exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-dpa-jsx": () => import("./../../../src/pages/dpa.jsx" /* webpackChunkName: "component---src-pages-dpa-jsx" */),
  "component---src-pages-gdpr-jsx": () => import("./../../../src/pages/gdpr.jsx" /* webpackChunkName: "component---src-pages-gdpr-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landingfive-jsx": () => import("./../../../src/pages/landingfive.jsx" /* webpackChunkName: "component---src-pages-landingfive-jsx" */),
  "component---src-pages-landingfour-jsx": () => import("./../../../src/pages/landingfour.jsx" /* webpackChunkName: "component---src-pages-landingfour-jsx" */),
  "component---src-pages-landingone-jsx": () => import("./../../../src/pages/landingone.jsx" /* webpackChunkName: "component---src-pages-landingone-jsx" */),
  "component---src-pages-landingthree-jsx": () => import("./../../../src/pages/landingthree.jsx" /* webpackChunkName: "component---src-pages-landingthree-jsx" */),
  "component---src-pages-landingtwo-jsx": () => import("./../../../src/pages/landingtwo.jsx" /* webpackChunkName: "component---src-pages-landingtwo-jsx" */),
  "component---src-pages-paysuccess-jsx": () => import("./../../../src/pages/paysuccess.jsx" /* webpackChunkName: "component---src-pages-paysuccess-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-privacypolicy-jsx": () => import("./../../../src/pages/privacypolicy.jsx" /* webpackChunkName: "component---src-pages-privacypolicy-jsx" */),
  "component---src-pages-sellinformation-jsx": () => import("./../../../src/pages/sellinformation.jsx" /* webpackChunkName: "component---src-pages-sellinformation-jsx" */),
  "component---src-pages-technicalperspective-jsx": () => import("./../../../src/pages/technicalperspective.jsx" /* webpackChunkName: "component---src-pages-technicalperspective-jsx" */),
  "component---src-pages-termsofservice-jsx": () => import("./../../../src/pages/termsofservice.jsx" /* webpackChunkName: "component---src-pages-termsofservice-jsx" */),
  "component---src-templates-blog-entry-js": () => import("./../../../src/templates/blog-entry.js" /* webpackChunkName: "component---src-templates-blog-entry-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

